export default function Error() {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column flex-grow-1">
      <h2>An error occurred while downloading data</h2>
      <p className="lead">
        Try checking your network connection and refresh the page.
      </p>
    </div>
  );
}
