import Link from "next/link";
import { ReactComponentElement, ReactElement, ReactNode } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import styles from "styles/components/sidebar/Sidebar.module.scss"
import { boolean } from "yup";



function SidebarAnchor({href, active, children, callback}:{href: string,active: boolean, children: ReactNode, callback?: ()=>void}) :JSX.Element{
  var activeClass = active? styles.sidebarAnchorActive: styles.sidebarAnchorNotActive;
  if (callback){
    return (<div onClick={()=>callback()} className={`${styles.sidebarAnchor} ${activeClass}`}>
      {children}
    </div>)
  }
    return(
      <Link href={href} passHref>
        <a className={`${styles.sidebarAnchor} ${activeClass}`}>
          {children}
        </a>
      </Link>
    )



}

export interface SidebarLinkProps {
  href: string;
  children: ReactNode;
  Icon: any;
  containerStyle?: any;
  callback?:()=>void;
}

export default function SidebarLink({ href, children, Icon, containerStyle={},callback}: SidebarLinkProps) {
  const router = useRouter();

  return (
    <div className={styles.containerButton} style={containerStyle}>
        <SidebarAnchor callback = {callback} href = {href} active={ router.pathname === href }>
          <Icon/>
          {children}
        </SidebarAnchor>
    </div>
  );
}
