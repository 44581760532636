import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const FixedHeight = styled.div<{ height?: string }>`
  height: ${({ height }) => height};
`;

export interface LoadingProps {
  height?: string;
}

export default function Loading({ height }: LoadingProps) {
  return (
    <FixedHeight height={height} className="text-center">
      <Spinner animation="border" />
    </FixedHeight>
  );
}
