import { type } from "os"
import React, { useEffect, useState } from "react";
import { number } from "yup/lib/locale";
import styles from "styles/components/topbar/Topbar.module.scss"

type NotificationType=number;
interface pro{
    notificationArray: Array<NotificationType>
}

function NotificationBody({notificationArray}:pro):JSX.Element{
    // This is where the No notifications or the ul are 
    if (notificationArray.length==0){
        return(
            <div className={styles.noNotifications}>
                You have no notifications
            </div>
        )
    }else{
        return (
            <></>
        )
    }
}


export default function NotificationMenu(){
    //For now the website doesn't have notifications
    // Actually you will have to use li

    //
    const [rightOffset, setRightOffset] =useState(51);
    function reposition(){
        var notificationButton = document.getElementById("notificationButton")
        if (notificationButton){
            var rect = notificationButton.getBoundingClientRect();
            setRightOffset(document.body.clientWidth - rect.left -117)
        }
    }

    useEffect(()=>{
        reposition()
        window.addEventListener("resize",reposition);
        return () => {
            window.removeEventListener('resize', reposition);
        }
        })


    var notificationArray:Array<NotificationType> = []
    return(
  <div style={{right:`${rightOffset}px`}} className={styles.notificationMenu}> 
    <h4>Notifications</h4>
    <div className={styles.notificationSep}></div>
    <p>You have no notifications</p>

  </div> 

  )
}
