import styles from "styles/components/topbar/Topbar.module.scss"
import { useRouter } from "next/router";
import { UserComponent } from "./UserComponent/UserComponent";

export default function Topbar() {
  var router = useRouter()
    return (
  <div id="topbarContainer" className={styles.topbarContainer}>
     <div className = {styles.topbarItems} id="topbar-itemsr">
        <div className={styles.leftSide}>
{/*           <div className={styles.arrowContainer}>
            <div onClick={()=>router.back()} id="back-arrow" className={styles.arrowCircle}>
              <svg width="17" height="27" viewBox="0 0 17 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.3813 27L0.625 13.5L14.3813 0L16.375 1.9708L4.61234 13.5L16.375 25.0292L14.3813 27Z" fill="#134563"/>
              </svg>
            </div>
                                        <div id="foward-arrow" className={styles.arrowCircle}>
              <svg width="17" height="27" viewBox="0 0 17 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.61867 6.02398e-07L16.375 13.5L2.61867 27L0.625 25.0292L12.3877 13.5L0.625 1.9708L2.61867 6.02398e-07Z" fill="#134563"/>
              </svg>
            </div>
          </div>  */}

          <div id = "cwd" className={styles.cwd}></div>
        </div>
        <div className={styles.rightSide}>
          <UserComponent />
        </div>
    </div>


  </div>
    )
}