
import { callbackify } from "util";
import styles from "./Overlay.module.scss"
interface props{
    children: React.ReactNode;
    callback: (displayType: string) => void
}

export default function Overlay({children,callback}:props){
    //You have to put overlay in a div, where the div controls the display
    //use callback function with arg "none" when you should close the display

    function closeDisplay(){
        document.body.style.overflow = "visible";
        callback("none");
    }
    const handleChildElementClick = (e:any) => {
        e.stopPropagation() 
     }

    return(
        <>
        <div onClick={()=>closeDisplay()} className={styles.fullWindow} id="overlay">
                <div></div>
                <div onClick = {(e)=>handleChildElementClick(e)} className={styles.innerWindow}>
                    <button className = {styles.closeButton} onClick={()=>closeDisplay()}>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0445 0.425781C5.44642 0.425781 0.996635 4.87557 0.996635 10.4737C0.996635 16.0718 5.44642 20.5216 11.0445 20.5216C16.6427 20.5216 21.0924 16.0718 21.0924 10.4737C21.0924 4.87557 16.6427 0.425781 11.0445 0.425781ZM11.0445 19.0862C6.30767 19.0862 2.43205 15.2106 2.43205 10.4737C2.43205 5.73682 6.30767 1.8612 11.0445 1.8612C15.7814 1.8612 19.657 5.73682 19.657 10.4737C19.657 15.2106 15.7814 19.0862 11.0445 19.0862Z" fill="black"/>
                    <path d="M14.9202 15.4976L11.0445 11.622L7.16892 15.4976L6.02058 14.3493L9.8962 10.4737L6.02058 6.59804L7.16892 5.44971L11.0445 9.32533L14.9202 5.44971L16.0685 6.59804L12.1929 10.4737L16.0685 14.3493L14.9202 15.4976Z" fill="black"/>
                    </svg>
                    </button>
                    <div className={styles.childDiv}> 
                        {children}
                    </div>
                </div>
                <div></div>
            </div>
            <div className = {styles.tint}></div>
        </>
        
    )
}