import { signOut } from "next-auth/react";
import Link from "next/link";
import React, { ReactNode, useEffect, useState } from "react";
import styles from "styles/components/topbar/Topbar.module.scss"

interface propInterface{
  children: ReactNode;
  href: string;
  callback: (s:string)=>void;
}
function MenuLink({href,children,callback}:propInterface):JSX.Element{
  return (
  <Link  href={href}>
    <a onClick={()=>callback("account")} className={styles.menuLink}>
        {children} 
    </a>
  </Link>
  );
}


export default function AccountMenu({args}:any){
    //For now the website doesn't have AccountMenus
    // Actually you will have to use li
    const [rightOffset, setRightOffset] =useState(51);
    function reposition(){
        var AccountMenuButton = document.getElementById("AccountMenuButton")
        if (AccountMenuButton){
            var rect = AccountMenuButton.getBoundingClientRect();
            setRightOffset(document.body.clientWidth - rect.left -170)
        }
    }

    useEffect(()=>{
        reposition()
        window.addEventListener("resize",reposition);
        return () => {
            window.removeEventListener('resize', reposition);
        }
        })


    return(
  <div style={{right:`${rightOffset}px`}} className={styles.accountMenu}> 
    <div className={styles.profileName}>
      <p>{args.firstName+" "+args.lastName}</p>
      <p>{args.email}</p>
    </div>

      <MenuLink  callback={args.callback} href = "/portfolio">
          <p>My Portfolio</p>
      </MenuLink>
      <MenuLink callback={args.callback}  href = "/settings" >
        <p>Settings</p>
      </MenuLink>
      <MenuLink  callback={args.callback} href="/help">
      <p>Help</p>
      </MenuLink>
      <div style = {{width:"100%",height:"100%"}} onClick={()=>signOut()}>
        <div className={styles.menuLink} >
          <p>Log out</p>
        </div >
      </div>


  </div> 

  )
}
