import { Feedback } from "../../lib/server/models";
import { useSession } from "next-auth/react";
import { Formik, Form, Field, useField, FormikHelpers } from 'formik';
import FormGroup from "../FormGroup";
import FormTextArea from "../FormTextArea";
import * as yup from "yup";
import styles from "./Feedback.module.scss"
import { useState } from "react";
import LoadingButton from "../LoadingButton";
import { client } from "../../lib/client/client";
import { FetchResponse } from "bent";
import { ClientError } from "../../lib/api-types";

export const MyTextArea = ({ ...props}:any) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
      <div>
          <label className={styles.label} htmlFor={props.id || props.name}>{props.label}</label>
          <textarea placeholder = {props.placeholder} className="form-control" {...field} {...props} />
          {meta.touched && meta.error ? (
              <div className={styles.error}>{meta.error}</div>
          ) : null}
      </div>
  );
};

export default function FeedbackForm({callback}:{callback:(arg:string)=>void}){
  const { data: session, status } = useSession();
  const [buttonState, setButtonState] = useState("ready");
  async function closeOut() {
    await new Promise(
      resolve => setTimeout(resolve, 1500)
    )
    document.body.style.overflow = "visible";
    callback("none");
  }

  async function onSubmit(values:{feedback:string,followup:string},
    helpers:FormikHelpers<{feedback:string,followup:string}>){
    let new_feedback: Feedback = {
      user: session?.user?.email,
      questionAnswer: [{question:"What is the feedback you have?",
          answer:values.feedback},
          {question:"How will we follow up?",answer:values.followup}],
      date: new Date(),
      type: "sendFeedbackLeftBar"
    }

    setButtonState("loading");
    try {
      const res = await client<FetchResponse>(
        "POST",
        201,
        400
      )(`/api/feedback`, new_feedback);
      if (res.statusCode >= 400) {
        const { error } = (await res.json()) as ClientError;
        setButtonState("error");
        helpers.setErrors({ [error.field!]: error.message });
      } else {
        setButtonState("success");
        helpers.resetForm();
        await closeOut()
      }
    } catch (error) {
      setButtonState("error");
      console.error(error);
    }

    //Send this to api/feedback
  }
  //use window.sessionStorage
  

  return (
    <div>
      <Formik
        initialValues={{feedback:"",followup:""}}
        onSubmit={onSubmit}
        validationSchema={yup.object().shape({
            feedback: yup.string()
            .max(10000,"The maximum amount of characters is 10000.")
            .required("You left the feedback blank!"),
            followup: yup.string()
            .max(10000,"The maximum amount of characters is 10000.")
            .notRequired(),

          })}>
          {({isSubmitting, isValid}) =>(
        <Form className={styles.formContainer}>
          <MyTextArea
              label="What is the feedback you have?"
              name="feedback"
              rows="6"
            />
            <div>
             <label className={styles.label}>If you would like us to follow up with you, please tell us how we can contact you.</label>
              <Field name="followup" > 
              {({
                field, // { name, value, onChange, onBlur }
                form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
              }:any) => (
                <div>
                  <input className="form-control" type="text" placeholder = "Optional" {...field} />
                  {meta.touched && meta.error && (
                    <div className={styles.error}>{meta.error}</div>
                  )}
                </div>
              )}
              </Field>
            </div>
            <LoadingButton
                status={buttonState}
                isSubmitting={isSubmitting}
                isValid={isValid}
                className="w-100"
              >
                Submit
              </LoadingButton>
            {/* <button id="feedbackSubmitButton" className="btn btn-primary" type="submit">Submit</button> */}
        </Form>)}
      </Formik>
    </div>
  )
}


