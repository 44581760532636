import Image from "next/image";
import styles from "styles/components/topbar/Topbar.module.scss"
import { useEffect, useState } from "react";
import { signIn, useSession } from "next-auth/react";
import useSWR from "swr";
import { Json, OneUser } from "../../../lib/api-types";
import { client } from "../../../lib/client/client";
import Error from "../../Error";
import Loading from "../../Loading";
import defaultProfilePic from "./defaultProfilePic.png"
import { useRouter } from "next/router";
import NotificationMenu from "./NotificationMenu";
import AccountMenu from "./AccountMenu";
import { callbackify } from "util";



export function UserComponent(){
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [activeMenu, setActiveMenu] = useState("")

    var profile_pic_image = defaultProfilePic.src;
    const { data, error, mutate } = useSWR(
      "/api/user",
      client<Json<OneUser>>("json")
    );
    const { data: session, status } = useSession();
    function toggleMenu(menuPressed:string){
        if (menuPressed===activeMenu){
          setActiveMenu("");
        }else{
          setActiveMenu(menuPressed)
        }
      }

    
    if (status == "authenticated") {
        if (error) return <Error />;
        if (!data) return <Loading />;
    
        if (session.user?.image) {
            profile_pic_image = session.user.image;
        }

        if (first_name == "" && last_name == "") {
            if (data.firstName && data.lastName) { //This comes from their verifying their name
                setFirstName(data.firstName);
                setLastName(data.lastName);
            } else if (session?.user?.name) {
                    //seperate into two
                    var splitArr = session.user.name.split(" ");
                    if (splitArr.length > 1) {
                        var f_name = "";
                        for (let i = 0; i < splitArr.length - 1; i++) {
                            f_name += splitArr[i] + " ";
                        }
                        setFirstName(f_name);
                        setLastName(splitArr[splitArr.length - 1]);
                    } else {
                        setFirstName(splitArr[0]);
                    }
            } else {
                session?.user?.email ? setFirstName("NAME") : 0;
            }
        }
        return(
            <>
            <div id = "notificationButton" onClick={()=>toggleMenu("notification")} className={styles.notificationButton} >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.02 2.91003C8.71 2.91003 6.02 5.60003 6.02 8.91003V11.8C6.02 12.41 5.76 13.34 5.45 13.86L4.3 15.77C3.59 16.95 4.08 18.26 5.38 18.7C9.69 20.14 14.34 20.14 18.65 18.7C19.86 18.3 20.39 16.87 19.73 15.77L18.58 13.86C18.28 13.34 18.02 12.41 18.02 11.8V8.91003C18.02 5.61003 15.32 2.91003 12.02 2.91003Z" stroke="#333333" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                {/* <circle id="notificationCircle" cx="19.02" cy="5.93994" r="4" fill="#FB0101"/> */}
                <path d="M13.87 3.19994C13.56 3.10994 13.24 3.03994 12.91 2.99994C11.95 2.87994 11.03 2.94994 10.17 3.19994C10.46 2.45994 11.18 1.93994 12.02 1.93994C12.86 1.93994 13.58 2.45994 13.87 3.19994Z" stroke="#333333" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15.02 19.0601C15.02 20.7101 13.67 22.0601 12.02 22.0601C11.2 22.0601 10.44 21.7201 9.9 21.1801C9.36 20.6401 9.02 19.8801 9.02 19.0601" stroke="#333333" stroke-width="1.5" stroke-miterlimit="10"/>
                </svg>
            </div>
        
            <div onClick={()=>toggleMenu("account")} className={styles.profile}>
                <div className={styles.profilePic}>
                    <Image
                    src={profile_pic_image}
                    alt="profile-pic"
                    width={48}
                    height={48}/>
                </div>
            </div>
            <div className={activeMenu==="notification"?styles.active:styles.hidden}>
              <NotificationMenu/>
            </div>
            <div className={activeMenu==="account"?styles.active:styles.hidden}>
              <AccountMenu args = { {email: session?.user?.email,
                                    firstName: first_name,
                                    lastName: last_name,
                                    callback: toggleMenu}}/>
            </div>
            </>
            )
    }else if (status=="unauthenticated"){
        return(
            <button className = "btn btn-primary" onClick={()=>signIn()}>
                Log in
            </button>
        )
    }else{
        return(
            <Loading />
        )
    }





  
   
}