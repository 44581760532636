import { AppProps } from "next/app";
import Head from "next/head";
import { ErrorBoundary } from "@sentry/nextjs";
import { SessionProvider } from "next-auth/react";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { useState } from "react";
import "../styles/globals.scss"; //This automatically styles divs and stuff
import Sidebar from "../components/sidebar/Sidebar";
import Auth from "../components/Auth";
import Topbar from "../components/topbar/Topbar";
import styles from "../styles/pages/_app.module.scss"
import FeedbackForm from "../components/feedback/FeedbackForm";
import Overlay from "../components/overlay/Overlay";
config.autoAddCss = false;


export default function App({ Component, pageProps }: AppProps) {
  const [feedbackOverlay, setFeedbackOverlay] = useState("none")
  return (
    <>
      <Head>
        <link rel="icon" href="/logo-blue.png" />
        <title>Ticker Market</title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Tomorrow:wght@400;500;700&display=swap"></link>
      </Head>
      <SessionProvider>

        <ErrorBoundary
          fallback={
            <div className="d-flex align-items-center justify-content-center flex-column flex-grow-1">
              <h2>A fatal error occurred</h2>
              <p className="lead">Try refreshing the page.</p>
            </div>
          }
        >
          <Auth>
            <div className={styles.overlayStackingContext}>
              {/* HTML elements here are in the highest stacking context.  */}
              <div style={{display:feedbackOverlay}}>
                <Overlay callback={setFeedbackOverlay}>
                  <FeedbackForm callback={setFeedbackOverlay}/>
                </Overlay>
              </div>
            </div>
            <Topbar />
            <Sidebar setFeedbackOverlay = {setFeedbackOverlay}/>
            <div className={styles.innerWindow} id="innerWindow">
                <Component {...pageProps} />
            </div>

          </Auth>
        </ErrorBoundary>
      </SessionProvider>
    </>
  );
}
